// Static Assets
const image_no_image = '/assets/images/misc/no-image.svg'

const defaultOptions = {
	format: 'jpg',
	quality: '70',
}

export const getCdnImage = (url, paramOptions) => {
	if(url && typeof url === 'string') {
		const urlParams = (() => {
			const options = { ...defaultOptions, ...paramOptions };
			const params = [];
			for(const param of Object.keys(options)) {
				switch(param) {
					case 'width':
						params.push(`w=${options[param]}`)
					break;
					case 'height':
						params.push(`h=${options[param]}`)
					break;
					case 'format':
						params.push(`format=${options[param]}`)
					break;
					case 'quality':
						params.push(`quality=${options[param]}`)
					break;
					case 'bgColor':
						params.push(`bgcolor=${options[param]}`)
					break;
					case 'mode':
						params.push(`mode=${options[param]}`)
					break;
				}
			}
	
			if(params.length) {
				return '?'+params.join('&');
			}
			else {
				return '';
			}
		})()
	
		return `${url.startsWith(process.config.cdnURL) ? '' : process.config.cdnURL}${url}${urlParams}`;
	}
	else {
		return image_no_image;
	}
}

export default getCdnImage;