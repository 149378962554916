import '/assets/styles/pages/home.scss';
import { Fragment, useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';

// Sections
import MainSlider from '/views/sections/home-mainslider';
import Camppaigns from '/views/sections/home-campaigns';
import Categories from '/views/sections/home-categories';
import SubSlider from '/views/sections/home-subslider';
// import HomeShowcase from '/views/sections/home-showcase';

// Async Sections
const LazyCampaignSlider = lazy(() => (import('/views/sections/home-campaignslider')));
const LazyMidBanner = lazy(() => (import('/views/sections/home-midbanner')));
const LazyShowcase = lazy(() => (import('/views/sections/home-showcase')));
const LazyTripleBanner = lazy(() => (import('/views/sections/home-triplebanner')));
// const LazyCategories = lazy(() => (import('/views/sections/home-categories')));
const LazyApplink = lazy(() => (import('/views/sections/home-applink')));

// Partials
import EmptyPlaceholder from 'pstv-commerce-tools/partials/empty-placeholder';
import LazyComponent from 'pstv-commerce-tools/partials/lazy-component';

// Services
import siteServices from '/services/site';

// Context
import { useBreakpoints } from 'pstv-commerce-tools/utilities/breakpoints';

// Functions
import getCdnImage from '/functions/get-cdn-image'

const getCampaignCdnImage = (image, width, height, quality = 50) => {
	return getCdnImage(image, {
		width: width,
		height: height,
		bgColor: 'white',
		quality: quality,
		mode: 'crop',
	});
}

const getMainSliderData = (desktop) => {
	return siteServices.getBanners(desktop ? 1 : 6);
}

const getShowcaseData = () => {
	return siteServices.getHomeShowcases();
}

const getSubSliderData = () => {
	return siteServices.getBanners(2);
}

const getCampaignData = () => {
	return new Promise((resolve, reject) => {
		siteServices.getCampaigns().then(campaigns => {
			resolve(campaigns.map(campaign => (
				{
					...campaign,
					images: {
						web: getCampaignCdnImage(campaign.image, 413, 163, 80),
						web2x: getCampaignCdnImage(campaign.image, 826, 326),
						mobile: getCampaignCdnImage(campaign.image, 678, 268),
					}
				}
			)));
		}).catch(reject);
	});
}

// const getShowcase1 = () => {
// 	return siteServices.getShowcase(2);
// }

const Homepage = ({ pageProps }) => {
	const { desktop } = useBreakpoints();

	const [mainSliderData, setMainSliderData] = useState(pageProps?.mainSliderData ?? false);
	const [campaignData, setCampaignData] = useState(pageProps?.campaignData ?? false);
	const [subSliderData, setSubSliderData] = useState(pageProps?.subSliderData ?? false);
	const [showcaseData, setShowcaseData] = useState(pageProps?.showcaseData ?? false);
	// const [showcase1Data, setShowcase1Data] = useState(pageProps?.showcase1Data ?? false);

	useEffect(() => {
		if(!mainSliderData || (mainSliderData && mainSliderData.desktop !== desktop)) {
			setMainSliderData(false);
			getMainSliderData(desktop).then((data) => {
				setMainSliderData({ slides: data, desktop });
			}, [])
		}
	}, [mainSliderData, desktop])
	
	useEffect(() => {
		if(!showcaseData) {
			getShowcaseData().then((data) => {
				setShowcaseData(data);
			}, [])
		}
	}, [showcaseData])

	useEffect(() => {
		if(!campaignData && desktop) {
			getCampaignData().then((data) => {
				setCampaignData(data);
			}, [])
		}
	}, [campaignData, desktop])

	useEffect(() => {
		if(!subSliderData && desktop) {
			getSubSliderData().then((data) => {
				setSubSliderData(data);
			}, [])
		}
	}, [subSliderData, desktop])

	// useEffect(() => {
	// 	if(!showcase1Data) {
	// 		getShowcase1().then((data) => {
	// 			setShowcase1Data(data);
	// 		}, [])
	// 	}
	// }, [showcase1Data])

	return (
		<>
			<MainSlider data={mainSliderData?.slides} />

			{desktop &&
				<>
					<Camppaigns data={campaignData} />
					<SubSlider data={subSliderData} />
					{/* <HomeShowcase className="showcase-1" horizontal data={showcase1Data} /> */}
				</>
			}

			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ marginTop: '2rem', paddingBottom: '6.66796875%' }}
					mobileStyles={{ marginTop: '1.1rem', paddingBottom: '29.552%' }} />
			}>
				<LazyCampaignSlider />
			</LazyComponent>

			<Categories />

			{/* <LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ height: '41rem' }} />
			}>
				<LazyCategories />
			</LazyComponent> */}

			{desktop &&
				<LazyComponent placeholder={
					<EmptyPlaceholder
						styles={{ marginTop: '4rem', paddingBottom: '14.830508474576271%' }}
						mobileStyles={{ marginTop: '4rem', paddingBottom: '54.572271386430678%' }} />
				}>
					<LazyMidBanner />
				</LazyComponent>
			}

			{!showcaseData || showcaseData?.[0] &&
				<LazyComponent key="showcase-1" placeholder={
					<EmptyPlaceholder
						styles={{ 'height': '45.2rem' }} />
				}>
					<LazyShowcase
						showTitle
						className="showcase-1"
						showcaseID={showcaseData[0].id} />
				</LazyComponent>
			}

			{!desktop &&
				<LazyComponent placeholder={
					<EmptyPlaceholder
						styles={{ marginTop: '4rem', paddingBottom: '14.830508474576271%' }}
						mobileStyles={{ marginTop: '4rem', paddingBottom: '54.572271386430678%' }} />
				}>
					<LazyMidBanner />
				</LazyComponent>
			}

			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ 'height': '24.1rem' }} />
			}>
				<LazyTripleBanner />
			</LazyComponent>

			{((showcaseData?.length ?? 0) > 1) &&
				<>
					{showcaseData.map((showcase, nth) => (
						<Fragment key={`showcase-${nth + 2}`}>
							{nth > 0 &&
								<LazyComponent placeholder={
									<EmptyPlaceholder
										styles={{ 'height': '45.2rem' }} />
								}>
									<LazyShowcase
										showTitle
										className="showcase-looped"
										showcaseID={showcase.id} />
								</LazyComponent>
							}
						</Fragment>
					))}
				</>
			}

			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ 'height': '17.8rem' }} />
			}>
				<LazyApplink />
			</LazyComponent>
		</>
	)
}

Homepage.propTypes = {
	pageProps: PropTypes.object
}

Homepage.getServerProps = ({ mediaStatus }) => {
	const isDesktop = mediaStatus?.desktop ?? false;
	return new Promise((resolve) => {
		Promise.all([
			getMainSliderData(isDesktop),
			getShowcaseData(),
			isDesktop ? getCampaignData() : new Promise((resolve) => resolve(false)),
			isDesktop ? getSubSliderData() : new Promise((resolve) => resolve(false)),
			// isDesktop ? getShowcase1() : new Promise((resolve) => resolve(false)),
		]).then(([
			mainSliderData,
			showcaseData,
			campaignData,
			subSliderData,
			// showcase1Data,
		]) => {
			resolve({
				pageProps: {
					mainSliderData: { slides: mainSliderData, desktop: isDesktop },
					showcaseData,
					campaignData,
					subSliderData,
					// showcase1Data,
				},
			})
		}).catch(() => {
			resolve({
				status: 500,
			})
		})
	})
}

export default Homepage
