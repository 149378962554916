import '/assets/styles/partials/slider.scss';

import loadable from '@loadable/component';
import SliderPlaceholder from 'pstv-commerce-tools/partials/slider/slider-placeholder';

const LazySlider = loadable(() => import('pstv-commerce-tools/partials/slider/slider'), { ssr: false });

const Slider = (props) => {
	return (
		<LazySlider {...props} fallback={<SliderPlaceholder {...props} />} />
	)
}

export default Slider;
