import '/assets/styles/sections/home-mainslider.scss';

import { useMemo } from 'react';
import PropTypes from 'prop-types';

// Context
import { useBreakpoints } from 'pstv-commerce-tools/utilities/breakpoints';

// Partials
import Slider from '/views/partials/slider';
import Placeholder from '/views/partials/placeholder';
import Img from 'pstv-commerce-tools/partials/img';
import Link from 'pstv-commerce-tools/partials/link';

const HomeSlider = ({ data }) => {
	const { desktop } = useBreakpoints();

	const slides = useMemo(() => {
		return (data && data[0]?.slides) ? data[0].slides : data;
	}, [data])

	if(!(slides && slides.length === 0)) {
		return (
			<section className="section home-mainslider">
				<div className={`${desktop ? 'wrapper ' : ''}slider-wrap`}>
					{slides ?
						<Slider
							className="mainslider-slider"
							pagination={true}
							autoplay={5000}
							id="home-mainslider"
							navigation={desktop && slides.length > 1}>
							{slides.map((slide, nth) => (
								<Link
									className="slider-slide"
									href={slide.link}
									raw
									key={nth}>
									<Img
										className="slide-image"
										src={slide.image_url}
										lazy={nth > 0}
										loading={nth === 0 ? 'eager' : undefined}
										alt={slide.title || `Slayt ${nth + 1}`} />
								</Link>
							))}
						</Slider>
						:
						<Placeholder className="mainslider-slider" />
					}
				</div>
			</section>
		)
	}
	else {
		return false;
	}
}

HomeSlider.propTypes = {
	data: PropTypes.array,
}

export default HomeSlider;
