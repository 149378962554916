import '/assets/styles/sections/home-subslider.scss';

import PropTypes from 'prop-types';

// Partials
import Slider from '/views/partials/slider';
import Placeholder from '/views/partials/placeholder';
import Img from 'pstv-commerce-tools/partials/img';
import Link from 'pstv-commerce-tools/partials/link';

const SubSlider = ({ data: slides }) => {

	if(!(slides && slides.length === 0)) {
		return (
			<section className="section home-subslider">
				<div className="wrapper slider-wrap">
					{slides ?
						<Slider
							className="subslider-slider"
							params={{
								slidesPerView: 3,
							}}
							id="home-subslider"
							navigation={slides.length > 3}>
							{slides.map((slide, nth) => (
								<Link
									className="slider-slide"
									href={slide.link}
									raw
									key={nth}>
									<Img
										className="slide-image"
										src={slide.image_url}
										lazy={nth > 2}
										loading={nth > 2 ? undefined : 'eager'}
										alt={slide.title || `Slayt ${nth + 1}`} />
								</Link>
							))}
						</Slider>
						:
						<Placeholder className="subslider-slider" />
					}
				</div>
			</section>
		)
	}
	else {
		return false;
	}
}

SubSlider.propTypes = {
	data: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.bool,
	]),
}

export default SubSlider;
