import '/assets/styles/sections/home-campaigns.scss';

import PropTypes from 'prop-types';

// Partials
import Slider from '/views/partials/slider';
import Placeholder from '/views/partials/placeholder';
import Img from 'pstv-commerce-tools/partials/img';
// import Link from 'pstv-commerce-tools/partials/link';

// Context
import { useBreakpoints } from 'pstv-commerce-tools/utilities/breakpoints';
import { useModals } from 'pstv-commerce-tools/utilities/modals';

const Campaigns = ({ data: campaigns }) => {
	const { desktop } = useBreakpoints();
	const { openModal } = useModals();

	if(!(campaigns && campaigns.length === 0)) {
		return (
			<section className="section home-campaigns">
				<div className="wrapper slider-wrap">
					{campaigns ?
						<Slider
							className="campaigns-slider"
							params={{
								slidesPerView: 3,
							}}
							id="home-campaigns"
							navigation={campaigns.length > 3}>
							{campaigns.map((campaign, nth) => (
								<button
									type="button"
									onClick={() => {
										openModal('campaignDetail', { campaign: campaign })
									}}
									className="slider-slide"
									key={nth}>
									<Img
										className="slide-image"
										cover
										srcSet={desktop ? `${campaign.images.web2x} 2x` : undefined}
										src={desktop ? campaign.images.web : campaign.images.mobile}
										lazy={nth > 2}
										loading={nth > 2 ? undefined : 'eager'}
										alt={campaign.sort_description || `Slayt ${nth + 1}`} />
								</button>
								// <Link
								// 	className="slider-slide"
								// 	href="campaignDetail"
								// 	params={{ id: campaign.id }}
								// 	key={nth}>
								// 	<Img
								// 		className="slide-image"
								// 		srcSet={desktop ? `${campaign.images.web2x} 2x` : undefined}
								// 		src={campaign.images.web}
								// 		lazy={nth > 2}
								// 		loading={nth > 2 ? undefined : 'eager'}
								// 		alt={campaign.sort_description || `Slayt ${nth + 1}`} />
								// </Link>
							))}
						</Slider>
						:
						<Placeholder className="campaigns-slider" />
					}
				</div>
			</section>
		)
	}
	else {
		return false;
	}
}

Campaigns.propTypes = {
	data: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.bool,
	]),
}

export default Campaigns;
