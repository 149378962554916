import "/assets/styles/sections/home-categories.scss";
import kebabCase from "lodash/kebabCase";

// Partials
import Link from "pstv-commerce-tools/partials/link";
import Img from "pstv-commerce-tools/partials/img";
import Icon from "pstv-commerce-tools/partials/icon";

// Context
import { useGlobalState } from "pstv-commerce-tools/utilities/global-state";

// Static Assets
import image_icon_discount from "/assets/images/categories/icon-discount.svg";
import image_icon_new from "/assets/images/categories/icon-new.svg";

const Categories = () => {
    const { categories } = useGlobalState();

    // Custom List - Vegan Products
    const veganSubCategory = {
        name: "Vegan Ürünler",
        id: process.config.staticCustomLists?.veganProducts.id,
        slug: process.config.staticCustomLists?.veganProducts.slug,
        list_image:
            "https://cdn.metro-group.com/tr/metroonlinevegan17112023.png", // Vegan Products CDN
    };

    const modifiedCategories = [...(categories || []), veganSubCategory];

    if (categories) {
        return (
            <section className="section home-categories">
                <div className="wrapper categories-wrap">
                    <h2 className="categories-title">Tüm Kategoriler</h2>
                    <div className="custom-list-wrapper">
                        {process.config.staticCustomLists
                            ?.discountedProducts && (
                            <li className="list-category highlight">
                                <Link
                                    href="customList"
                                    className="category-link-custom"
                                    params={{
                                        id: process.config.staticCustomLists
                                            ?.discountedProducts.id,
                                        slug: process.config.staticCustomLists
                                            ?.discountedProducts.slug,
                                    }}
                                >
                                    <div className="category-imagewrap">
                                        <Img
                                            className="imagewrap-image"
                                            src={image_icon_discount}
                                        />
                                    </div>
                                    <strong className="category-title">
                                        {"Öne Çıkan Ürünler"}
                                    </strong>
                                    <Icon
                                        name="arrow-right"
                                        className="custom-list-icon"
                                    />
                                </Link>
                            </li>
                        )}
                        {process.config.staticCustomLists?.newProducts && (
                            <li className="list-category highlight">
                                <Link
                                    href="customList"
                                    className="category-link-custom"
                                    params={{
                                        id: process.config.staticCustomLists
                                            ?.newProducts.id,
                                        slug: process.config.staticCustomLists
                                            ?.newProducts.slug,
                                    }}
                                >
                                    <div className="category-imagewrap">
                                        <Img
                                            className="imagewrap-image"
                                            src={image_icon_new}
                                        />
                                    </div>
                                    <strong className="category-title">
                                        Yeni Ürünler
                                    </strong>
                                    <Icon
                                        name="arrow-right"
                                        className="custom-list-icon"
                                    />
                                </Link>
                            </li>
                        )}
                    </div>
                    <ul className="categories-list">
                        {modifiedCategories.map((category) => (
                            <li className="list-category" key={category.id}>
                                <Link
                                    href={
                                        category.id !== 23
                                            ? "category"
                                            : "customList"
                                    } // Manual check for veganProducts
                                    className="category-link"
                                    params={{
                                        id: category.id,
                                        slug:
                                            category.slug ??
                                            kebabCase(category.name),
                                    }}
                                >
                                    <div className="category-imagewrap">
                                        <Img
                                            className="imagewrap-image"
                                            src={category.list_image}
                                        />
                                    </div>
                                    <strong className="category-title">
                                        {category.name}
                                    </strong>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
        );
    }
};

export default Categories;
